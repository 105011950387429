.footer {

  &__row {
    text-align: center;
    padding: 25px 0;

    @include media(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left; } }

  &__main {

    @include media(md) {
      display: flex; } }

  &__logo {
    font-size: 0;
    line-height: 1;

    img {} }

  &__content {
    display: none;

    @include media(md) {
      display: flex;
      margin-left: 25px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        @include center-y();
        height: 60px;
        border-left: 1px solid $yellow; } }

    &-item {

      @include media(md) {
        display: flex;
        align-items: center;
        padding-left: 40px;
        font-size: 14px;
        line-height: 1.35;

        @include media(lg) {
          padding-left: 45px;
          padding-right: 45px; }

        .icon-calendar {
          font-size: 24px;
          color: $yellow;
          margin-right: 12px; } } } }

  &__contact {

    @include media(md) {
      text-align: right;
      padding-right: 65px;
      background: url('../images/contact.svg') no-repeat right center;
      background-size: auto 20px; }

    @include media(lg) {
      padding-right: 70px; } }

  &__phone {
    font-size: 22px;
    font-weight: 700;
    display: block; }

  &__callback {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #3971b5;
    border-bottom: 2px dotted #3971b5;
    text-decoration: none;

    &:hover {
      border-bottom: 2px dotted transparent;
      text-decoration: none; } } }
