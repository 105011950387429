.popup {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;

  &.is-sended {

    .popup__container {
      background-color: $blue; }

    .popup__close {
      color: #fff; }

    .popup__form-fields {
      display: none; }

    .popup__form-success {
      display: block; } }

  &__inner {
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    text-align: center;

    &::before {
      content: "";
      display: none;
      height: 100%;
      vertical-align: middle;
      width: 0;
      margin-right: -.3em; }

    @include media(xs) {
      padding: 10px 10px 0;

      &::before {
        display: inline-block; } } }

  &__container {
    position: relative;
    z-index: 1000;
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    max-width: 600px;
    margin-bottom: 0;
    padding: 35px 15px 20px;
    border: 10px solid $blue;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    border-radius: 0;
    overflow: hidden;
    transform: translateY(-15%);
    opacity: 0;
    transition: transform 0.6s ease, max-width 0.4s ease;

    @include media(xs) {
      width: 99.9%;
      margin-bottom: 10px;
      min-height: 0;
      border: 20px solid $blue; }

    @include media(sm) {
      padding: 60px 15px; } }

  &__overlay {
    position: fixed;
    opacity: 0.8;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 999; }

  &__close {
    @include reset-btn();
    border: none;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: $blue;
    line-height: 1;

    .icon {
      font-size: 16px;
      transition: transform 0.35s ease; }

    &:hover {
      color: $yellow;

      .icon {
        transform: rotate(90deg); } } }

  &__title {
    text-align: center;
    font-size: 24px;
    color: $blue;
    margin: 0 0 15px; }

  &__form {

    &-fields {
      max-width: 325px;
      margin: 0 auto; }

    &-intro {
      text-align: center;
      font-weight: 700;
      margin-bottom: 25px; }

    &-success {
      display: none;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      color: #fff;
      padding-top: 25px; } } }



