.section-pickup {
  padding-bottom: 30px;

  @include media(md) {
    padding-bottom: 60px; }

  @include media(lg) {
    padding-bottom: 70px; }

  &__formbox {

    @include media(md) {
      margin-left: 50%;
      position: relative;

      &::before {
        content: '';
        width: 1000px;
        height: 700px;
        position: absolute;
        right: 90%;
        bottom: -80px;
        background: url('../images/courier.png') no-repeat right top;
        background-size: auto 100%;
        z-index: 1;

        @include media(lg) {
          height: 730px;
          right: 85%;
          bottom: -90px; } } }

    @include media(lg) {
      margin-left: calc(50% + 60px); } } }
