.section-partner {
  padding: 40px 0;

  @include media(md) {
    padding-top: 80px;
    padding-bottom: 80px;
    background: url('../images/map-bg.jpg') no-repeat center center; }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px; }

  &__row {

    @include media(md) {
      display: flex;
      justify-content: space-between;
      align-items: center; } }

  &__info {
    margin-bottom: 30px;

    @include media(sm) {
      margin-bottom: 45px; }

    @include media(md) {
      padding-right: 25px; }

    &-header {
      margin-bottom: 25px;

      br {
        display: none; }

      @include media(md) {
        margin-bottom: 40px; }

      @include media(lg) {

        br {
          display: inline; } }

      h2 {
        margin: 0;
        color: $blue;

        @include media(md) {
          text-align: left; } }

      span {
        font-weight: 600;
        display: block;
        color: #000;
        font-size: 14px;
        margin-top: 5px;

        @include media(md) {
          font-size: 16px;
          margin-top: 10px; } } }

    &-list {

      @include media(lg) {
        margin-left: 10%; } }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      font-size: 15px;

      br {
        display: none; }

      @include media(md) {
        margin-bottom: 30px; }

      @include media(lg) {
        font-size: 20px;

        br {
          display: inline; } } }

    &-icon {
      width: 82px;
      height: 82px;
      border: 2px dashed $blue;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      color: $blue;
      flex-shrink: 0;

      .icon {
        font-size: 34px; } } }

  &__logo {
    display: flex;
    align-items: center;

    @include media(lg) {
      margin-left: 10%; }

    img {
      display: block;
      margin-right: 12px;
      width: auto;
      max-height: 35px;

      @include media(xs) {
        max-height: 55px;
        margin-right: 20px; }

      &:last-child {
        margin-right: 0; } } }

  &__formbox {

    @include media(md) {
      width: 50%;
      flex-shrink: 0; }

    @include media(lg) {
      width: calc(50% - 60px); } } }
