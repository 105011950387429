@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-display: swap;
    font-family: quote($font-name);
    src: url($path+".eot");
    src: url($path+".eot?#iefix") format("embedded-opentype"), url($path+".woff") format("woff"), url($path+".woff2") format("woff2"), url($path+".svg##{$font-name}") format("svg");
    font-weight: $weight;
    font-style: $style; } }

@mixin reset-list() {
  margin: 0;
  padding: 0;
  list-style-type: none; }

@mixin center-x() {
  left: 50%;
  transform: translateX(-50%); }

@mixin center-y() {
  top: 50%;
  transform: translateY(-50%); }

@mixin center-xy() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@mixin reset-input() {
  appearance: none;
  line-height: 1;
  font-family: $ff;
  border-width: 1px;
  transition: all 0.2s ease;

  &:focus {
    outline: none; } }

@mixin reset-btn() {
  appearance: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  border-width: 1px;
  line-height: 1;
  font-family: $ff;
  transition: all 0.2s ease;

  &:hover {
    text-decoration: none; }

  &:focus {
      outline: none; } }
