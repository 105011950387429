.section-map {
  padding-top: 320px;
  padding-bottom: 40px;
  background-color: #f8f8f8;
  position: relative;

  @include media(md) {
    padding-top: 120px;
    padding-bottom: 120px;
    height: 665px; }

  &__holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

  &__wide {
    display: none;

    @include media(md) {
      display: block;
      width: 100%;
      height: 100%; } }

  &__mob {
    width: 100%;
    height: 100%;

    @include media(md) {
      display: none; } }

  &__contact {
    margin-left: -12px;
    margin-right: -12px;
    position: relative;
    z-index: 1;

    @include media(md) {
      display: flex;
      justify-content: flex-end;
      width: 570px;
      float: right; }

    &-holder {
      padding: 45px 12px;
      box-shadow: 0 0 25px rgba(63,63,63,0.2);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media(xs) {
        padding: 45px 35px;
        border-radius: 12px;
        max-width: 570px; }

      @include media(md) {
        width: 100%;
        padding-top: 80px;
        padding-bottom: 80px; } }

    &-row {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;
      width: 350px;
      max-width: 100%;

      &:last-child {
        margin-bottom: 0; }

      p {
        margin: 0 0 7px; }

      img {
        flex-shrink: 0;
        margin-right: 10px; }

      strong {
        display: block;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 0.05em;
        margin-bottom: 5px; } } } }
