.section-top {
  padding-bottom: 60px;
  padding-top: 220px;
  background: url('../images/top-bg-mob.jpg') no-repeat center top;
  margin-top: -180px;

  @include media(sm) {
    margin-top: -125px;
    padding-top: 170px; }

  @include media(md) {
    background: url('../images/top-bg.jpg') no-repeat center top;
    background-size: auto 100%; }

  @include media(lg) {
    background-size: cover;
    min-height: 920px;
    padding-top: 200px; }

  &__content {

    &-top {
      text-align: center;

      @include media(md) {
        text-align: left; }

      h1 {
        font-size: 18px;
        font-weight: 900;
        margin: 0;

        @include media(md) {
          font-size: 36px; }

        @include media(lg) {
          font-size: 44px; }

        span {
          font-weight: 400;
          display: block; } }

      p {
        margin: 50px 0 25px;
        font-size: 18px;

        @include media(md) {
          font-size: 22px; }

        @include media(lg) {
          font-size: 24px; }

        strong {
          display: block; } } } }

  &__btn {
    width: 100%;
    max-width: 450px;

    @include media(md) {
      max-width: 320px; } }

  &__benefits {
    @include reset-list();
    margin-top: 35px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(md) {
      flex-direction: row;
      padding: 0;
      margin-top: 85px; }

    @include media(lg) {
      margin-top: 150px; }

    &-item {
      display: flex;
      align-items: center;
      width: 250px;

      @include media(md) {
        font-size: 14px;
        width: 245px; }

      @include media(lg) {
        width: 300px; }

      &:not(:last-child) {
        margin-bottom: 15px;

        @include media(md) {
          margin-bottom: 0; } } }

    &-icon {
      width: 82px;
      height: 82px;
      flex-shrink: 0;
      border: 2px dashed $yellow;
      border-radius: 50%;
      color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      .icon-calendar {
        font-size: 36px; }

      .icon-security {
        font-size: 36px; }

      .icon-delivery {
        font-size: 31px; } } } }
