.section-benefits {
  background-color: $grey-bg;
  padding: 40px 0;

  @include media(md) {
    padding-top: 80px;
    padding-bottom: 80px; }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px; }

  &__list {

    @include media(md) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 50px; }

    @include media(lg) {
      justify-content: space-around; } }

  &__item {

    @include media(md) {
      width: 45%;
      max-width: 425px;
      margin-bottom: 35px;

      &:nth-last-child(-n+2) {
        margin-bottom: 0; } }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      strong {

        font-size: 20px;
        line-height: 1.2; } }

    &-icon {
      width: 82px;
      height: 82px;
      border: 2px dashed $blue;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      color: $blue;

      .icon-gift {
        font-size: 32px; }

      .icon-stopwatch {
        font-size: 36px; }

      .icon-truck {
        font-size: 26px; }

      .icon-shield {
        font-size: 32px; } } } }
