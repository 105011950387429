.checkbox {
  display: inline-block;

  &__input {
    display: none;

    & + span {
      display: inline-block;
      position: relative;
      padding: 0 0 0 25px;
      line-height: 1.1;
      cursor: pointer;
      font-size: 13px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 14px;
        height: 14px;
        line-height: 12px;
        border: 1px solid $blue;
        border-radius: 2px;
        background-color: #fff; }

      a {
        color: $blue; } }

    &:checked {
      & + span:before {
        content: '';
        background: url('../images/check-blue.svg') no-repeat 2px 3px;
        background-size: 9px auto;
        border: 1px solid $blue;
        box-shadow: 0 0 0 1px $blue;
        text-align: center; } } } }
