h2 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  margin: 0 0 30px;

  br {
    display: none; }

  @include media(md) {
    font-size: 30px;
    margin-bottom: 40px; }

  @include media(lg) {

    br {
      display: inline; } } }

p {
  margin: 0 0 25px; }

a {
  color: $blue;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    text-decoration: underline; }

  &:focus {
    outline: none; }

  &[href^="tel:"] {
    text-decoration: none;
    color: inherit;
    border-bottom: none;
    white-space: nowrap;
    cursor: default; } }
