@import "variables";

@mixin media($size) {
  @if $size == xxs {
    @media only screen and (min-width: $screen-xxs) {
      @content; } }

  @if $size == xs {
    @media only screen and (min-width: $screen-xs) {
      @content; } }

  @if $size == sm {
    @media only screen and (min-width: $screen-sm) {
      @content; } }

  @if $size == md {
    @media only screen and (min-width: $screen-md) {
      @content; } }

  @if $size == lg {
    @media only screen and (min-width: $screen-lg) {
      @content; } }

  @if $size == xl {
    @media only screen and (min-width: $screen-xl) {
      @content; } } }
