.master {
  height: 100%;

  &__video {
    position: relative;
    height: 100%;

    &:after {
      content: '';
      width: 48px;
      height: 48px;
      background: url('../images/play.svg') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      @include media(lg) {
        display: none; } }

    &.is-played::after {
      display: none; }

    video {
      width: 100%;
      height: auto;
      display: block; } }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      display: block; } } }
