.btn {
  @include reset-btn();
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80px;
  border: none;
  border-radius: 8px;
  min-width: 180px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 700;
  transition: all 0.2s ease;

  &._wide {
    width: 100%; }

  &._blue {
    background-color: $blue;
    color: #fff;

    &:hover,
    &:focus {
      background-color: lighten($blue, 7%); } }

  &._white {
    color: $blue;
    background-color: #fff;

    &:hover,
    &:focus {
      background-color: lighten($blue, 45%); } }

  &[disabled],
  &.is-disabled {
    background-color: #e6e6e6;
    color: #8a8a8a;
    pointer-events: none;
    box-shadow: none; } }

