.section-service {
  background-color: $grey-bg;
  padding: 40px 0;

  @include media(md) {
    padding: 80px 0;

    h2 {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto; } }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px; }

  &__list {
    max-width: 450px;
    margin: 0 auto;

    @include media(md) {
      max-width: none;
      display: flex;
      justify-content: space-between; } }

  &__item {
    display: block;
    margin-bottom: 35px;
    margin-left: -12px;
    margin-right: -12px;
    position: relative;
    overflow: hidden;
    padding: 0 12px 25px;
    color: $text;
    text-decoration: none;

    &:hover {
      text-decoration: none; }

    @include media(md) {
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;
      width: 32%;
      padding: 0 20px 25px; }

    &::before {
      content: '';
      position: absolute;
      top: 110px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff; }

    &-holder {
      position: relative;
      z-index: 1; }

    &-img {
      height: 220px;
      text-align: center;
      margin-bottom: 20px;

      img {
        max-height: 220px;
        max-width: 100%; } }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2;

      &:hover {
        text-decoration: underline; } }

    &-price {

      strong {
        font-size: 36px;
        color: $blue; } } } }
