.icon {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    fill: currentColor; }

.icon-arrow-down {
    font-size: (158.2/10)*1rem;
    width: (256/158.2*1em); }
.icon-calendar {
    font-size: (512/10)*1rem;
    width: (512/512*1em); }
.icon-copy {
    font-size: (512/10)*1rem;
    width: (512/512*1em); }
.icon-cross {
    font-size: (8.4/10)*1rem;
    width: (8.4/8.4*1em); }
.icon-delivery {
    font-size: (355/10)*1rem;
    width: (512/355*1em); }
.icon-fast {
    font-size: (512/10)*1rem;
    width: (512/512*1em); }
.icon-gears {
    font-size: (418.879/10)*1rem;
    width: (418.879/418.879*1em); }
.icon-gift {
    font-size: (512/10)*1rem;
    width: (512/512*1em); }
.icon-phone {
    font-size: (387.2/10)*1rem;
    width: (387.2/387.2*1em); }
.icon-security {
    font-size: (214.27/10)*1rem;
    width: (214.27/214.27*1em); }
.icon-shield {
    font-size: (428.16/10)*1rem;
    width: (428.16/428.16*1em); }
.icon-stopwatch {
    font-size: (511.48/10)*1rem;
    width: (511.48/511.48*1em); }
.icon-truck {
    font-size: (269.8/10)*1rem;
    width: (462.5/269.8*1em); }
.icon-truck2 {
    font-size: (512/10)*1rem;
    width: (512/512*1em); }
.icon-user {
    font-size: (415.74/10)*1rem;
    width: (415.74/415.74*1em); }
.icon-wallet {
    font-size: (890.5/10)*1rem;
    width: (890.5/890.5*1em); }
