*,
*:after,
*:before {
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%; }

body {
  min-width: 320px;
  min-height: 100%;
  font-family: $ff;
  font-size: $fsize;
  font-weight: $fw;
  line-height: $lh;
  color: $text;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.is-popup-open {
    overflow: hidden;

    &::after {
      visibility: visible;
      opacity: 1; }

    .popup__container {
      transform: translateY(0);
      opacity: 1; } } }
