.section-proccess {
  padding-top: 40px;

  @include media(md) {
    padding-top: 80px;
    padding-bottom: 30px; }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 70px; }

  &__row {

    @include media(md) {
      display: flex;
      justify-content: space-between; } }

  &__video {
    margin-left: -12px;
    margin-right: -12px;

    @include media(md) {
      width: calc(50% - 20px);
      margin: 0; }

    @include media(lg) {
      padding-right: 20px; }

    @include media(xl) {
      padding-right: 30px; } }

  &__text {
    margin-top: 30px;
    margin-bottom: 15px;

    @include media(md) {
      width: calc(50% - 20px);
      margin-top: 0;
      margin-bottom: 0; } }

  &__steps {
    @include reset-list();

    li {
      margin-bottom: 25px;
      position: relative;
      padding-left: 18px;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: $blue;
        position: absolute;
        left: 0;
        top: 7px; } } } }
