*::-webkit-input-placeholder {
  color: #999999; }

*::-moz-placeholder {
  color: #999999; }

*:-ms-input-placeholder {
  color: #999999; }

*:focus::-webkit-input-placeholder {
  color: transparent !important; }

*:focus::-moz-placeholder {
  color: transparent !important; }

*:focus:-ms-input-placeholder {
  color: transparent !important; }

input[type=search]::-ms-clear {
  display: none;
  width : 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width : 0;
  height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; }

textarea {
  appearance: none;
  display: block;
  width: 100%;
  resize: none; }
