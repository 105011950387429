.section-promo {
  padding-bottom: 30px;

  @include media(lg) {
    padding-bottom: 50px; }

  &__formbox {

    @include media(md) {
      margin-left: 50%;
      position: relative;

      &::before {
        content: '';
        width: 1000px;
        height: 580px;
        position: absolute;
        right: 90%;
        bottom: -50px;
        background: url('../images/clients.png') no-repeat right top;
        background-size: auto 100%;
        z-index: 1;

        @include media(lg) {
          height: 720px;
          right: 85%;
          bottom: -70px; } } }

    @include media(lg) {
      margin-left: calc(50% + 60px); } } }
