.formbox {
  border-top: 20px solid $blue;
  border-bottom: 20px solid $blue;
  padding: 25px 12px 35px;
  margin-left: -12px;
  margin-right: -12px;
  background-color: #fff;

  @include media(xs) {
    border: 20px solid $blue;
    padding: 25px 30px 35px; }

  @include media(md) {
    margin-left: 0;
    margin-right: 0;
    padding: 55px 30px 45px; }

  h2 {

    span {
      display: block;
      color: $blue; }

    @include media(md) {

      font-size: 24px; } }

  p {
    font-weight: 600;
    text-align: center;
    line-height: 1.35;

    @include media(md) {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto; } }

  &__fields {

    @include media(md) {
      max-width: 320px;
      margin: 0 auto; } }

  &__footnote {
    font-size: 12px;
    text-align: center;
    margin-top: 10px; } }
