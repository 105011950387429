.videobox {
  position: relative;
  text-align: center;
  overflow: hidden;
  display: block;

  @include media(md) {
    box-shadow: 15px 15px 0 0 $yellow; }

  @include media(lg) {
    box-shadow: 20px 20px 0 0 $yellow; }

  @include media(xl) {
    box-shadow: 30px 30px 0 0 $yellow;
    width: 115%;
    margin-left: -15%; }

  img {
    width: 100%;
    display: block; }

  &__play {
    position: absolute;
    @include center-xy();
    border-radius: 50%;
    padding: 25px;
    background-color: rgba(255,255,255,0.15);
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: translate(-50%, -50%) scale(1.1,1.1);

      .videobox__play-icon {
        transform: scale(0.85,0.85); } }

    &-icon {
      width: 100px;
      height: 100px;
      background: $blue url('../images/play.png') no-repeat center center;
      background-size: 50px auto;
      border: 25px solid $blue;
      border-radius: 50%;
      display: block;
      transition: all 0.3s ease; } } }
