.container {
  padding-left: 12px;
  padding-right: 12px;
  max-width: 600px;
  margin: 0 auto;

  @include media(md) {
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px; } }
