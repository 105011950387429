.inputbox {
  margin-bottom: 25px;

  &__label {
    font-size: 14px;
    color: #949494;
    margin-bottom: 15px; }

  &__field {
    position: relative; }

  &__input {
    @include reset-input();
    border: 1px solid #f5f5f5;
    border-top: 2px solid #d0d0d0;
    background: #f5f5f5;
    border-radius: 8px;
    padding: 26px 65px 26px 25px;
    font-size: 16px;
    line-height: 24px;

    &:focus {
      border: 1px solid #e4e4e4;
      border-top: 2px solid #d0d0d0; }

    &._wide {
      width: 100%; }

    &.is-error {
      border: 1px solid $red;
      border-top: 2px solid $red; } }

  &__icon {
    position: absolute;
    width: 65px;
    line-height: 78px;
    text-align: center;
    right: 0;
    top: 0;
    color: #d0d0d0;

    .icon-user {
      font-size: 18px; }

    .icon-phone {
      font-size: 20px; } }

  &__error {
    color: $red;
    font-size: 12px;
    line-height: 1.2;
    margin-top: 3px; } }
