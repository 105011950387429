.section-about {
  padding: 40px 0;
  background-color: $grey-bg;

  @include media(md) {
    padding-top: 80px;
    padding-bottom: 80px;

    h2 {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto; } }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px; }

  @include media(xl) {

    .container {
      position: relative;

      &::before {
        content: '';
        background: url('../images/decor-bg.svg') repeat;
        width: 290px;
        height: 410px;
        position: absolute;
        left: -90px;
        top: -305px;
        transform: translateX(-100%); }

      &::after {
        content: '';
        background: url('../images/decor-bg.svg') repeat;
        width: 290px;
        height: 190px;
        position: absolute;
        right: -90px;
        bottom: -195px;
        transform: translateX(100%); } } }

  &__list {
    @include reset-list();

    @include media(md) {
      display: flex;
      justify-content: space-between;
      margin-top: 55px; } }

  &__item {

    @include media(md) {
      width: 31%; }

    &:not(:last-child) {
      margin-bottom: 20px;

      @include media(md) {
        margin-bottom: 0; } }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px; }

    &-icon {
      width: 82px;
      height: 82px;
      flex-shrink: 0;
      background-color: $blue;
      border: 11px solid $grey-bg;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 15px;

      &:after {
        content: '';
        width: calc(100% + 22px);
        height: calc(100% + 22px);
        position: absolute;
        left: -11px;
        top: -11px;
        border: 2px dashed $yellow;
        border-radius: 50%; }

      .icon-gears {
        font-size: 28px; }

      .icon-fast {
        font-size: 30px; }

      .icon-shield {
        font-size: 30px; } }

    strong {
        font-size: 20px;
        line-height: 1.2; }

    p {
      margin: 0; } } }
