.section-masters {
  padding: 40px 0 30px;

  @include media(md) {
    padding-top: 80px;
    padding-bottom: 80px; }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px; }

  @include media(xl) {

    .container {
      position: relative;

      &::after {
        content: '';
        background: url('../images/decor-bg.svg') repeat;
        width: 290px;
        height: 410px;
        position: absolute;
        right: -90px;
        bottom: -250px;
        transform: translateX(100%); } } }

  &__scroller {
    margin-left: -12px;
    margin-right: -12px;
    overflow-x: auto;
    white-space: nowrap;

    @include media(md) {
      margin-left: 0;
      margin-right: 0;
      white-space: normal;
      overflow: hidden; } }

  &__list {
    display: flex;

    @include media(md) {
      align-items: flex-start;
      flex-wrap: wrap;
      margin: -15px; } }

  &__item {
    width: 285px;
    flex-shrink: 0;
    padding: 0 12px;

    @include media(md) {
      width: 25%;
      padding: 15px; } } }
