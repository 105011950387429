.selectstyled {
  width: 100%; }

.select-hidden {
  display: none;
  visibility: hidden; }

.select {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 16px;
  color: $text;
  width: 100%; }

.select-styled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  text-align: left;
  font-size: 16px;
  color: $text;
  padding-left: 25px;

  span {
    width: calc(100% - 80px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  &::after {
    content: "";
    width: 68px;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: url('../images/arrow-down.svg') no-repeat center center;
    background-size: 14px auto; }

  &.is-active {
    z-index: 7;

    &:after {
      transform: rotate(180deg); }

    & + .select-options-wrap {
      display: block; } } }

.select-options-wrap {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 15px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 3px 8px rgba(0,0,0,0.1);
  z-index: 6; }

.select-options {
  @include reset-list();

  &:focus {
    outline: none; }

  li {
    display: block;
    padding: 10px 25px;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 300;
    color: $text;

    &.is-selected {
      display: none; }

    &:hover {
      text-decoration: none; }

    &.is-disabled {
      display: none; } } }
