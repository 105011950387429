.section-calc {
  padding-top: 40px;

  @include media(xs) {
    padding-bottom: 40px; }

  @include media(md) {
    padding-top: 80px;
    padding-bottom: 80px; }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px; }

  &__row {

    @include media(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 420px;
      padding: 45px 65px;
      background: $grey-bg url('../images/bg-1.jpg') no-repeat 355px bottom;
      background-size: auto 100%; }

    @media only screen and (min-width: 1100px) {
      margin: 0 50px; }

    @include media(lg) {
      background-position: right bottom; } }

  &__form {
    background-color: $grey-bg;
    margin-left: -12px;
    margin-right: -12px;
    padding: 25px 12px 0;

    @include media(xs) {
      padding: 35px 40px; }

    @include media(md) {
      padding: 0;
      width: 320px;
      width: 40%;
      flex-shrink: 0;
      background: none; }

    &-label {
      font-size: 14px;
      color: #949494;
      margin-bottom: 15px; }

    &-field {
      padding-bottom: 20px; } }

  &__result {
    background-color: $blue;
    margin-left: -12px;
    margin-right: -12px;
    padding: 10px 12px 30px;
    color: #fff;
    text-align: center;

    @include media(xs) {
      padding: 25px 40px 30px; }

    @include media(md) {
      width: 50%; }

    &-sum {
      font-size: 60px;
      font-weight: 700;
      margin: 0 0 10px; } } }
